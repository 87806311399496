'use client'
//need to import all the needed packages
//npm install react-slick slick-carousel
//npx shadcn-ui@latest add card
//npx shadcn-ui@latest add button

import React,{useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from 'react-modal-video'
import Image from "next/image";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import playbutton from '@/public/home-images/white-play-button.png'
import playb1 from '@/public/home-images/playbutton.png'
import playb2 from '@/public/home-images/playbutton1.png'
import playb3 from '@/public/home-images/playbutton2.png'
import playb4 from '@/public/home-images/playbutton3.png'
import leftarrow from '@/public/images/about-us/left-arrow.png'
import rightarrow from '@/public/images/about-us/right-arrow.png'






function SliderComponent (props) {
    const [isOpen, setOpen] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState('');

    const slides = ['text-primaryGreen','text-primaryYellow','text-primaryBlue','text-primaryRed', 'text-primaryGreen'];
    const bgSlides =['bg-green-100','bg-yellow-100','bg-blue-200','bg-red-200','bg-green-100'];
    const border =['border-primaryGreen','border-primaryYellow','border-primaryBlue','border-primaryRed','border-primaryGreen'];
    const play =[ playb1, playb2 ,playb3 , playb4, playb1 ]
    

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                }
            }
        ]

    };

    const handleNext = () => {
        slider.slickNext();
    };

    const handlePrevious = () => {
        slider.slickPrev();
    };

    function extractYouTubeVideoId(url) {
        const match = url.match(/v=([a-zA-Z0-9_-]{11})/);
        return match ? match[1] : null;
    }

    let slider;
    // let playButton = '/home-images/white-play-button.png';
    return (
        <div className='carousal-container mt-4 mb-10'>
            
           
            {/* setshow */}
            
            <Slider {...settings} ref={(c) => (slider = c)} className='mt-0 custom-container'>
                {(new Date() < new Date("2024-06-24")) && (
                    <div className='lg:mt-3' key={5}>
                        <div className="lg:flex justify-center relative block">
                            <div className='relative box-border w-full h-auto'>
                                <Card className=" max-content border-none rounded-3xl object-cover relative lg:mt-0">
                                    <Image src={`https://bed.apexhospitals.com/uploads/IMG_20240617_WA_0010_98f9bb4150.jpg`} width={696} height={600} alt="text" loading='eager' priority className="rounded-3xl homecarousel object-fit w-screen lg:w-full lg:object-contain" 
                                        role='button'
                                        onClick={() => window.open('https://bit.ly/nursinggurukulworkshop', '_blank')} 
                                    />
                                </Card>
                            </div>
                            <div className='relative box-border w-auto h-auto'>
                                <Card className={`${bgSlides[1]} lg:ml-4 hometextcarousel lg:mt-0 rounded-3xl mt-4 w-auto h-max relative`}>
                                    <CardHeader className={`${slides[1]}`}>
                                        <h1 className='text-2xl font-semibold lg:text-5xl text-ellipsis sm:line-clamp-3 line-clamp-2 h-[74px] sm:h-auto overflow-hidden pb-2'>{props?.lang==='en' ? "NURSING GURUKUL 2024" : "नर्सिंग गुरुकुल 2024"}</h1>
                                    </CardHeader>
                                    <CardContent className="font-normal text-xl lg:text-2xl pb-0 px-6 pt-0 mb-6 text-primaryGreyText text-ellipsis line-clamp-7 overflow-hidden">
                                        {props?.lang==='en' ? "This session covers Leadership Mastery, Patient Happiness, Role of Modern Nurse, Introduction to Hospital P&L, Nurses as Brand Ambassadors, Nurse to Entrepreneur, Effective Communication, Mental Health, Cultural Competence." : "इस सत्र में नेतृत्व निपुणता, रोगी की खुशी, आधुनिक नर्स की भूमिका, अस्पताल पी एंड एल का परिचय, ब्रांड एंबेसडर के रूप में नर्स, उद्यमी से नर्स, प्रभावी संचार, मानसिक स्वास्थ्य, सांस्कृतिक क्षमता शामिल हैं।"}
                                    </CardContent>
                                    <CardDescription className="font-normal text-xl p-6 pt-0 text-gray-600">
                                        APEX HOSPITALS
                                    </CardDescription>
                                    <CardFooter className='flex justify-center w-full px-0 h-fit relative lg:bottom-0 lg:absolute'>
                                        <Button className={`bg-inherit border ${border[1]} rounded-[50px] h-auto ${slides[1]} py-3 whitespace-nowrap hover:bg-inherit watch-testimonials`} onClick={()=>{
                                            window.open('https://bit.ly/nursinggurukulworkshop', '_blank')
                                            // setOpen(true);
                                            // setCurrentVideoId(extractYouTubeVideoId(e?.attributes?.carouselLink));
                                        }}>
                                            {props?.lang==='en' ? "Register Now" : "अभी पंजीकरण करें"}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </div>
                )}
                {props.data?.data?.map((e,i)=>( 
                    <div className='lg:mt-3' key={i}>
                        <div className="lg:flex justify-center relative block">
                            <div className='relative box-border w-full h-auto'>
                                <Card className=" max-content border-none rounded-3xl object-cover relative lg:mt-0">
                                    <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e?.attributes?.carouselMedia.data?.attributes?.url}`} width={696} height={600} alt="text" loading='eager' priority className="rounded-3xl homecarousel object-fit object-left w-screen lg:w-full lg:object-cover" />
                                    <Image src={playbutton} width={80} height={80} alt='play' onClick={()=>{
                                            setOpen(true);
                                            setCurrentVideoId(extractYouTubeVideoId(e?.attributes?.carouselLink));
                                        }} className='absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2 cursor-pointer' priority loading='eager' />
                                </Card>
                            </div>
                            <div className='relative box-border w-auto h-auto'>
                                <Card className={`${bgSlides[i]} lg:ml-4 hometextcarousel lg:mt-0 rounded-3xl mt-4 w-auto h-max relative`}>
                                    <CardHeader className={`${slides[i]}`}>
                                        <h1 className='text-2xl font-semibold lg:text-5xl text-ellipsis sm:line-clamp-3 line-clamp-2 h-[74px] sm:h-auto overflow-hidden pb-2'>{e?.attributes?.carouselTitle}</h1>
                                    </CardHeader>
                                    <CardContent className="font-normal text-xl lg:text-2xl pb-0 px-6 pt-0 mb-6 text-primaryGreyText text-ellipsis line-clamp-7 overflow-hidden">
                                    {e?.attributes?.carouselDescription}
                                    </CardContent>
                                    <CardDescription className="font-normal text-xl p-6 pt-0 text-gray-600">
                                        {e?.attributes?.carouselAuthor}
                                    </CardDescription>
                                    <CardFooter className='flex justify-center w-full px-0 h-fit relative lg:bottom-0 lg:absolute'>
                                        {e?.attributes?.showButton && (
                                            <Button className={`bg-inherit border ${border[i]} rounded-[50px] h-auto ${slides[i]} py-3 whitespace-nowrap hover:bg-inherit watch-testimonials`} onClick={()=>{
                                                setOpen(true);
                                                setCurrentVideoId(extractYouTubeVideoId(e?.attributes?.carouselLink));
                                            }}>
                                                <Image src={play[i]} width={32} height={32} alt="play" className="mr-2 capitalize" />{e?.attributes?.buttonText || props.homeCarousel.buttonTitle}
                                            </Button>
                                        )}
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>

            <div className='flex justify-between relative'>
                <Button className="rounded-full bg-transparent border border-primaryGrey w-12 h-12 z-10 hover:bg-transparent hidden lg:absolute xl:bottom-62 lg:bottom-72 xl:left-12 lg:left-2 lg:inline-block" onClick={handlePrevious} >
                    <Image src={leftarrow} height={24} width={12} alt="left arrow" title='left arrow' priority loading='eager' />
                </Button>
                <Button className="rounded-full bg-transparent border border-primaryGrey w-12 h-12 z-10 hover:bg-transparent hidden lg:absolute xl:bottom-62 lg:bottom-72 xl:right-12 lg:right-2 lg:inline-block" onClick={handleNext}>
                    <Image src={rightarrow} height={24} width={12} alt="right arrow" title='right arrow' priority loading='eager' />
                </Button>
            </div>
            <ModalVideo channel='youtube' autoplay='1' isOpen={isOpen} videoId={currentVideoId} onClose={() => setOpen(false)}/> 
        </div>
    );
};

export default SliderComponent;
