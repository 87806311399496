'use client'
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from "next/image";
import Link from 'next/link';
import leftarrow from '@/public/images/about-us/left-arrow.png'
import rightarrow from '@/public/images/about-us/right-arrow.png'
import CustomLink from '../common/custom-link';

function Procedures(props) {
//    console.log(props.data?.data?[0].attributes?.procedureImage.data?.attributes?.url);

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                }
            },
            {
                breakpoint: 912,
                settings: {
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 798,
                settings: {
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    arrows: true,
                    dots: false,
                }
            }
        ]
    };

    const handleNext = () => {
        slider.slickNext();
    };

    const handlePrevious = () => {
        slider.slickPrev();
    };

    let slider;

    return (
        <div className='carousal-container lg:mb-20 mb-4 overflow-hidden' id='sec_procedures'>
            <div className="mb-6 m-3 ">
                <h2 className='header1 capitalize text-primaryBlue custom-container'>{props.procedur.title}</h2>
            </div>
            <Slider {...settings} ref={(c) => (slider = c)} className='custom-container'>
               {props.data?.data?.map((e,i)=>( <div key={i} className='mx-auto'>
                    <div className=" md:grid grid-cols-2 lg:gap-16 xl:gap-32 block">
                        <div className=" m-3">
                            <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e?.attributes?.procedureImage.data?.attributes?.url}`} alt={e?.attributes?.procedureImage.data?.attributes?.alternativeText || 'Apex Hospital Procedure'} title={e?.attributes?.procedureImage.data?.attributes?.alternativeText || 'Apex Hospital Procedure'} width={557} height={451} className='relative max-h-[450px] w-full h-auto object-cover object-center rounded-3xl'/>
                        </div>
                        <div className="flex items-start flex-col justify-center m-3 text-primaryGreyText relative">
                            <h2 className="text-xl md:text-2xl text-primaryGray font-semibold capitalize md:text-ellipsis md:line-clamp-2 md:overflow-hidden">{e?.attributes?.procedureTitle}</h2>
                            <p className="mt-2 sm:mb-14 mb-5 text-primaryGray p-text h-auto md:h-32 xl:h-auto overflow-y-scroll">{e?.attributes?.procedureShortDescription}</p>
                            <div className="flex items-center w-full sm:w-fit justify-center gap-4 mt-2 relative md:absolute bottom-0 right-2">
                                <CustomLink lang={props.lang} href='/get-estimate' className='border hover:text-primaryWhite  border-primaryBlue text-primaryBlue rounded-full bg-primaryWhite hover:bg-buttonbg whitespace-nowrap  text-xs sm:text-base px-6 py-2 lg:mr-3 procedures-estimate' id='procedures-estimate'>{props.procedur.getEstimate}</CustomLink>
                               {
                                   e?.attributes.procedureDescription === null ? null :
                                       <CustomLink lang={props.lang} href={`/procedure/${e?.attributes.procedureTag.replaceAll(/\s/g, '-')}`} className='bg-primaryBlue text-primaryWhite rounded-full border hover:border-primaryBlue hover:bg-primaryWhite hover:text-primaryBlue uppercase whitespace-nowrap  text-xs sm:text-base px-6 py-2 procedures-knowmore' id='procedures-knowmore'>{props.procedur.knowMore}</CustomLink>

                               }
                                {/* <Link href={`/procedure/${e.id}${e?.attributes?.procedureTitle.replaceAll(/\s/g,'')}`} className='bg-primaryBlue text-primaryWhite rounded-full border hover:border-primaryBlue hover:bg-primaryWhite hover:text-primaryBlue uppercase whitespace-nowrap  text-xs sm:text-base px-6 py-2 procedures-knowmore'>KNOW MORE</Link> */}
                            </div>
                        </div>
                    </div>
                </div>))}
            </Slider>
            <div className='lg:flex justify-between relative text-center'>
                <button className="rounded-full border border-primaryGrey bg-primaryWhite md:py-3 md:px-4 py-2 px-2 w-10 h-10 items-center justify-center md:w-auto md:h-auto z-10 relative lg:absolute bottom-0 lg:bottom-40 xl:bottom-48 lg:left-[7px]  xl:left-12 inline-flex" onClick={handlePrevious}>
                    <Image src={leftarrow} height={24} width={12} alt="left-arrow" title='left-arrow' />
                </button>
                <button className="rounded-full border border-primaryGrey bg-primaryWhite md:py-3 md:px-4 py-2 px-2 w-10 h-10 items-center justify-center md:w-auto md:h-auto z-10 relative lg:absolute bottom-0 lg:bottom-40 xl:bottom-48 lg:right-[7px] xl:right-12 right-2 inline-flex ml-5 lg:ml-0" onClick={handleNext}>
                    <Image src={rightarrow} height={24} width={12} alt="right-arrow" title='right-arrow' />
                </button>
            </div>
        </div>


    )
};

export default Procedures;