"use client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import Slider from "react-slick";

function OurFacilities(props) {
//  console.log(props.data?.data?)
  const styles =["lg:w-1/4 rounded-3xl lg:mr-6","lg:w-1/4  rounded-3xl lg:mr-6","lg:w-1/4  rounded-3xl lg:mr-44 xl:mr-56","lg:w-1/4 rounded-3xl lg:ml-44 xl:ml-56 mt-6 mb-10","lg:w-1/4  rounded-3xl lg:ml-6 mt-8 mb-10","lg:w-1/4  rounded-3xl lg:ml-6 mt-8 mb-10"]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 798,
        settings: {
            slidesToShow: 2,
            dots: true,
            arrows: false,
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            dots: true,
            arrows: false,
        }
    }
    ]
  };

    return (
        // desktop section starts
        <div className="custom-container mx-auto mt-0 lg:mt-0 lg:mb-20 mb-10">
            <h2 className={`${ props.color ? props.color : "text-primaryBlue" } header1 mb-0`} >{props.facilitie.title}</h2>
            <p className="text-primaryGreyText p-text font-normal mb-5 mt-2 lg:mb-10 w-full">{props.facilitie.content}</p>
            <div className="hidden lg:block">
                <div className="lg:flex  flex-wrap mx-auto justify-center">
                    {props.data?.data?.map((e, i) => (
                        <div key={i} className={styles[i]}>
                            <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e?.attributes?.facilityImage.data?.attributes?.url}`} width={340} height={200} alt={e?.attributes?.facilityTitle} title={e?.attributes?.facilityTitle} className="m-auto rounded-3xl" />
                            <p className="text-primaryGreyText font-bold mt-4 text-center text-lg leading-6"> {e?.attributes?.facilityTitle} </p>
                        </div>
                    ))}
                </div>
            </div>
            {/* desktop section ends */}

            {/* mobile section starts */}
            <div className="carousal-container block lg:hidden">
                <div className="mt-0 mb-20">
                    <Slider {...settings}>
                        {props.data?.data?.map((e, i) => (
                            <div className="rounded-3xl p-0 lg:p-2.5" key={i}>
                                <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e?.attributes?.facilityImage.data?.attributes?.url}`} width={340} height={200} alt={e?.attributes?.facilityTitle} title={e?.attributes?.facilityTitle} className="m-auto rounded-3xl" />
                                <p className="text-gray-400 font-bold mt-5 mb-5 text-center text-lg leading-6"> {e?.attributes?.facilityTitle} </p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {/* mobile section ends */}
        </div>
    );
}

export default OurFacilities;
