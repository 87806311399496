'use client'

import React, { useState } from 'react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import chevrondown from '@/public/home-appointment-images/chevron-down.png'
import CustomLink from '../common/custom-link';

function AppointmentForm ({location, speciality, lang, appointment}) {
    const router = useRouter();
    // console.log(speciality);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedSpeciality, setSelectedSpeciality] = useState('');

    const handleLocationSelect = (option) => {
        setSelectedLocation(option);
    };

    const handleSpecialitySelect = (option) => {
        setSelectedSpeciality(option);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log({ selectedLocation:selectedLocation.id, selectedSpeciality:selectedSpeciality.id});
        router.push(`/book/book?location=${selectedLocation.id?selectedLocation.attributes.locationCity:''}&speciality=${selectedSpeciality.id?selectedSpeciality.attributes.specialityName:''}`)
        
        setSelectedLocation('');
        setSelectedSpeciality('')
    };

    return (
    <div className="custom-container lg:mb-20 mb-10">
        <form onSubmit={handleSubmit} className="hidden md:flex items-center md:justify-center justify-start gap-5 lg:flex-nowrap flex-wrap h-auto my-6 lg:mt-0">
            <div className="relative box-border flex w-full gap-4 md:justify-start">
                <div className="flex w-full max-w-md justify-between items-center gap-2">
                    <label htmlFor="location" className="mr-2  text-black font-roboto text-base font-normal leading-6">
                        {appointment.location}
                    </label>
                    <DropdownMenu>
                        <DropdownMenuTrigger
                            type="button"
                            id="location"
                            className="sm:flex w-full items-center justify-between h-10 px-4 py-1 border border-gray-300 hidden rounded-full text-gray-600 outline-none truncate"
                        >
                            {selectedLocation ? selectedLocation.attributes.locationCity.toUpperCase() : appointment.selectLoc} <Image src={chevrondown} alt='arrow' height={24} width={24} className=' ml-16' />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="text-gray-600 text-base font-normal leading-6">
                          {location.map((e,i)=>(<DropdownMenuItem key={i} onClick={() => handleLocationSelect(e)}>
                               {e.attributes.locationCity.toUpperCase()}
                            </DropdownMenuItem>))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <div className="flex w-full max-w-md justify-between items-center gap-2">
                    <label htmlFor="speciality" className=" ml-6 mr-2 text-black font-roboto text-base font-normal leading-6">
                       {appointment.speciality}
                    </label>
                    <DropdownMenu>
                        <DropdownMenuTrigger
                            type="button"
                            id="speciality"
                            className="sm:flex w-full items-center justify-between h-10 px-4 py-1 border border-gray-300 hidden rounded-full text-gray-600 outline-none truncate uppercase"
                        >
                            {selectedSpeciality ? selectedSpeciality.attributes.specialityName.toUpperCase() : appointment.selectSpec} <Image src={chevrondown} alt='arrow' height={24} width={24} className=' ml-16' />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="text-gray-600 h-56 overflow-y-auto">
                           {speciality.map((e,i)=>(<DropdownMenuItem key={i} className='uppercase' onClick={() => handleSpecialitySelect(e)}>{
                            e.attributes.specialityName.toUpperCase()
                           }
                            </DropdownMenuItem>)) }
                            
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <CustomLink lang={lang} href={`/book/book?location=${selectedLocation.id?selectedLocation.attributes.locationCity:''}&speciality=${selectedSpeciality.id?selectedSpeciality.attributes.specialityName:''}`} className="w-fit text-primaryWhite bg-primaryBlue hover:bg-primaryBlue hover:border-primaryBlue whitespace-nowrap uppercase rounded-full h-12 px-4 py-1 flex items-center justify-center book-appointment-home1" id="book-appointment-home1">{appointment.book}</CustomLink>
        </form>
    </div>
    );
};

export default AppointmentForm;