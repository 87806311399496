'use client'

import Image from "next/image";
import React,{useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Link from "next/link";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from 'react-modal-video';
import playbutton from '@/public/home-images/white-play-button.png'
import CustomLink from "../common/custom-link";

function HearFromOurDoctors(props) {
  
    const [isOpen, setOpen] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState('');


    function extractYouTubeVideoId(url) {
        const match = url.match(/v=([a-zA-Z0-9_-]{11})/);
        return match ? match[1] : null;
    }

    // console.log(props.data.data)

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

    return (
        <>
        <div className=" block custom-container lg:grid grid-flow-col lg:grid-cols-2 md:grid-cols-1 grid-cols-1 lg:mb-20 mb-10">
            <div className="max-w-[457px]">
                <h2 className="text-primaryBlue header1">{props.hear.title}</h2>
                <p className=" text-primaryGreyText p-text mt-2">{props.hear.content}</p>
                <CustomLink lang={props.lang} href='/videos' className="bg-primaryBlue text-primaryWhite rounded-full border hover:border-primaryBlue hover:bg-primaryWhite hover:text-primaryBlue uppercase px-8 py-3 mt-5 relative lg:inline-block hidden home_hear_doctors" id="home_hear_doctors">{props.hear.button}</CustomLink>
            </div>
            <div className="hidden sm:grid grid-cols-2 gap-4 lg:mt-0 mt-5 md:mt-9">
               {props.data?.data?.map((e,i)=>(
               <div key={i} className="relative">
                    <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e.attributes?.doctorVideo?.data?.attributes?.url}`} alt="hear From our doctors" title="hear From our doctors" width={340} height={200} className="w-full h-full object-cover object-center rounded-3xl" />
                    <Image src={playbutton} alt="hear From our doctors" title="hear From our doctors" width={80} height={80}
                      onClick={()=>{setOpen(true);setCurrentVideoId(extractYouTubeVideoId(e?.attributes?.hearFromOurDoctorsLink));}}
                       className=" cursor-pointer z-10 absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2  w-10 h-10" />
                </div>)) }
                
            </div>
            <div className="block sm:hidden mt-8">
            <Slider {...settings} >
                {props.data?.data?.map((e,i)=>(
               <div key={i} className="relative focus:outline-none">
                    <Image src={`${process.env.NEXT_PUBLIC_BE_URL}${e.attributes?.doctorVideo?.data?.attributes?.url}`} alt="hear From our doctors" title="hear From our doctors" width={340} height={200} className="w-full h-full object-cover object-center rounded-3xl" />
                    <Image src={playbutton} alt="hear From our doctors" 
                        onClick={()=>{setOpen(true);setCurrentVideoId(extractYouTubeVideoId(e?.attributes?.hearFromOurDoctorsLink));}}
                         title="hear From our doctors" width={80} height={80} className=" z-10 absolute top-2/4 left-2/4 -translate-y-1/2 cursor-pointer -translate-x-1/2  w-10 h-10" />
                </div>)) }
            </Slider>
           
            </div>
            <div className="text-center">
                <CustomLink lang={props.lang} href='/videos' className="bg-primaryBlue inline-block text-primaryWhite rounded-full border hover:border-primaryBlue hover:bg-primaryWhite hover:text-primaryBlue uppercase px-8 py-3 mt-8 relative lg:hidden home_hear_doctors" id="home_hear_doctors">{props.hear.button}</CustomLink>
            </div>
        </div>
        <ModalVideo channel='youtube' autoplay='1' isOpen={isOpen} videoId={currentVideoId} onClose={() => setOpen(false)}/> 
        </>
    )
};


export default HearFromOurDoctors