import(/* webpackMode: "eager" */ "/vercel/path0/components/about-us/our-facilities.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/about-us/our-locations.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/apex-home-components/appointment-booking.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/apex-home-components/centers-of-excellence.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/apex-home-components/hear-from-doctors.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/apex-home-components/home-carousel.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/apex-home-components/procedure.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/locations/achievements.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/locations/why-apex.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/footer-images/app-store.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/footer-images/play-store.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/locations-images/mobile-app.png")